import logo from './logo.svg';
import './App.css';
import { useState ,useEffect } from 'react';

const App = () => {

  const [data, setData] = useState(null);

  const fetchDb = async () => {
    const result = await fetch("https://eyespydc-default-rtdb.firebaseio.com/.json/");
    const data = await result.json();
    setData(data);
  }
  useEffect(() => {
    fetchDb();
  }, [])
  return (
    <div className="App">
      {data && (
        <>
          <a target = '_blank' href = {data.video_link}>
            <img src = {data.gif_link} width = {data.gif_width} height = {data.gif_height}/>
          </a>
          <h2>{data.text}</h2>
          <h2>IG: <a target='_blank' href = {data.instagram_link}>{data.instagram_id}</a></h2>
        </>
      )}

    </div>
  );
}

export default App;
